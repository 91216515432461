.card-carousel-test {
    display: flex; 
    margin: 40px 20px;
  }
  
  .card-test {
    /* Existing styles */
    flex-shrink: 0; /* Prevent cards from shrinking */
    scroll-snap-align: start; /* Snap cards to the left edge */
    margin: 10px auto;
    width: 200px;
    text-wrap: wrap;
    border: 1px solid gray;
    border-radius: 10px;
    padding: 10px;
  }

  .card-body-test {
    margin-top: 20px;
  }

  .text-test {
    font-size: 12px;
  }

  .title-head {
    font-size: 16px;
  }

  .verified {
    background-color: #d9275c !important;
    padding: 3px 7px;
    border-radius: 5px;
    color: #FFFFFF;
    font-size: 10px;
  }

  @media (max-width: 768px) {
    .card-carousel-test {
      display: block; /* Make cards horizontal */
      justify-content: center;
      white-space: nowrap; /* Prevent wrapping on single line */
      margin: 40px 20px;
    }
   }
  