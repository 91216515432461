.doctor-content {
  max-width: 1082px;
  margin: 100px auto;
  height: 100%;
}

.doctor-content-old {
  display: flex;
  max-width: 1082px;
  margin: 100px auto;
  height: 100%;
}

.doctor-content-old > div {
  margin-right: 50px;
}


.doctor-content > div {
  margin-right: 50px;
}

.mob-img {
  display: none;
}

.specialisation-element {
  padding: 20px;
  margin: 10px 10px;
  border: 1px solid black;
  border-radius: 10px;
  background-color: #ec93ae;
  text-align: center;
  /* flex: 1;  */
}

#education ul li {
  list-style-type: disc;
  margin-left: 20px; /* Adjust the margin as needed */
  font-size: 16px;
}

.title-card-details {
  display: flex;
  margin: 0px 20px 50px;
}

.title-card-details > .left-details {
  width: fit-content;
}

.title-card-details > .right-details {
  width: fit-content;
  margin: auto 20px;
}

.education-section {
  margin-bottom: 40px;
}

.therapy_type {
  margin-bottom: 40px;
}
.specialisation {
  margin-bottom: 40px;
}

.spec-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 10px;
  /* display: flex;
  flex-wrap: wrap; */
}

.mobile-display {
  display: none;
}

.faq-right-icon {
  position: absolute;
  right: 15px;
  z-index: -1;
}

@media only screen and (max-width: 768px) {

  .mobile-display {
    display: block;
  }

  .title-card-details {
    display: flex;
    margin: 0px 20px 15px;
  }

  .title-card-details > .left-details {
    width: 35%;
  }

  .title-card > .right-details {
    width: 65%;
    margin: auto;
  }

  .education-section {
    margin-bottom: 0;
  }

  .therapy_type {
    margin-bottom: 0;
  }

  .spec-container {
    display: block;
  }

  .specialisation-element {
    margin: 10px 0;
  }

  .specialisation {
    margin-bottom: 0;
  }
  
  .doctor-content {
    display: block;
    margin: 20px;
  }

  .doctor-content > div {
    margin-right: 0px;
    padding: 10px;
  }

  .doctor-content-old {
    display: block;
    margin: 20px auto;
    text-align: center;
  }

  .doctor-content-old > div {
    margin-right: 0px;
    padding: 10px;
  }

  .desk-img {
    display: none;
  }

  .mob-img {
    display: block;
  }
}
