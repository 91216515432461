.block {
  display: block;
}

.checkout-step-one {
  display: none;
}

.section-left {
  width: 75%;
  margin: auto;
}

.coupon-section {
  display: flex;
  margin-bottom: 30px;
}

.coupon-section > button {
  border-radius: 0;
  padding: 5px 10px;
  margin-left: 5px;
}

.coupon-input {
  width: 75%;
}

.bill-details {
  max-width: 400px;
}

.checkout-step-two {
  display: none;
}

.checkout-step-three {
  display: none;
}

.active-step-one {
  display: flex;
}

.active {
  display: block;
}

.form-data {
  display: grid;
  margin: auto;
}

.checkout-heading {
  border-bottom: 1px solid #d9275c;
}

.already-text {
  background-color: #0000000d;
  padding: 5px 10px;
}

.already-text > span {
  text-decoration: underline;
}

.form-element > label {
  margin: 10px 0;
}

.form-element > input {
  padding: 20px 10px;
  width: 100%;
  margin-bottom: 5px;
}

.mobile-element > input {
  padding: 20px 10px;
  width: 80%;
  margin-bottom: 20px;
}

.mobile-order-summary {
  display: none;
}

.btn {
  border: none;
  background-color: #556b2f;
  padding: 10px 60px;
  border-radius: 40px;
  color: #ffffff;
}

.book-session {
  width: 80%;
}

.checkout-btn {
  position: fixed;
  bottom: 0;
  width: 50%;
  background: #ffffff;
  box-shadow: rgb(219, 219, 219) 0px -3px 6px;
  border-radius: 10px;
  text-align: center;
  left: 25%;
}

.input-width {
  width: 80%;
  margin: auto;
}

@media (max-width: 768px) {
  .checkout-btn {
    width: 100%;
    left: 0;
  }

  .active-step-one {
    display: block;
    margin: 10px;
  }

  .section-left {
    width: 100%;
  }

  .form-data {
    max-width: 100%;
  }

  .mobile-order-summary {
    padding: 20px 0;
    background-color: lightgrey;
    display: block;
  }

  .coupon-section {
    justify-content: center;
  }

  .coupon-section > button {
    width: 20%;
  }

  .btn {
    padding: 10px 30px;
    margin-left: 10px;
  }
}

.coupon-close {
  margin-left: -45px;
}

.pricing-box {
  display: flex;
  gap: 5px;
  margin-left: auto;
}

.original-price {
  text-decoration: line-through;
  color: #999;
  font-weight: 600;
}

.discounted-price {
  color: #222;
  font-weight: bold;
  font-size: 20px;
}

.card-main {
  justify-content: center;
  background-color: #ec93ae;
  padding: 10px;
}

.bottom-widget {
  background-color: #ec93ae;
}

.to-pay {
  font-size: 16px;
  margin-right: 20px;
  margin-top: 10px;
  margin-left: 20px;
  font-weight: bold;
}
