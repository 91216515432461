.header {
  position: relative;
  z-index: 1000000;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar {
  display: flex;
  justify-content: center !important;
  align-items: center;
  padding: 1rem 1.5rem;
  z-index: 10000001;
  background-color: white;
  width: 100%;
}

.fixed {
  position: fixed;
  z-index: 100;
  background-color: white;
  width: 100%;
}

.hamburger {
  display: none;
}

.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: #101010;
}

.nav-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 60px;
  margin-bottom: 0;
}

.nav-item {
  margin-left: 5rem;
}

.signup {
  font-weight: bold;
}

.logout {
  display: none;
}
.nav-link {
  font-weight: 400;
  cursor: pointer;
}

/* .nav-link:hover {
  color: #482ff7;
} */

.nav-logo {
  font-size: 2.1rem;
  font-weight: 500;
  color: #482ff7;
}

.title-img {
  height: 60px;
}

.menu-item-dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  width: 200px;
  background-color: gainsboro;
  top: 100%;
  padding: 0 15px;
  left: 0;
  z-index: 1;
}

.menu-item-dropdown:hover .dropdown-content {
  display: block;
}

@media only screen and (max-width: 768px) {
  .nav-menu {
    display: none;
    position: fixed;
    margin-top: 10px;
    margin-left: 0;
    top: 60px;
    flex-direction: column;
    background-color: #fff;
    width: 100%;
    border-radius: 10px;
    text-align: center;
    transition: 0.3s;
    box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
  }

  .nav-menu.active {
    display: block;
    left: 0;
  }

  .nav-item {
    margin: 2.5rem 0;
  }

  .hamburger {
    display: block;
  }

  .logout {
    display: block;
  }
}

.coupon-strip {
  background-color: #ec93ae;
  padding: 10px;
  position: relative;
  z-index: 100001;
  display: flex;
}
