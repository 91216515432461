/* ImageGrid.css */
.image-grid {
    display: flex;
    flex-direction: column;
    max-width: 400px;
  }
  
  .row {
    display: flex;
  }
  
  .image {
    flex: 1;
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }

  .mobile {
    display: none;
  }

  @media only screen and (max-width: 768px) {
  
    .mobile-grid {
      display: none;
    }

    .mobile {
      display: block;
    }
  
   }
  
  .image img {
    width: 100%;
    height: auto;
    display: block;
    position: relative;
    z-index: -1;
  }
  
  .large-img {
    flex: 2;
  }
  