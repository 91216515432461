.footer {
  background-color: #f5f5f5;
}

.footer > div {
  max-width: 1082px;
  margin: auto;
  padding: 10px 0 0;
  border-bottom: 2px solid #d9275c;
}

.footer-sec {
  display: flex;
}

.subs-input {
  border: 1px solid #000000;
  padding: 10px 50px;
}

.join {
  border: none;
  background-color: #556b2f;
  padding: 7px 20px;
  color: #ffffff;
}

.subscribe-input {
  display: flex;
  justify-content: center;
}

.btm-footer {
  display: flex;
  padding-top: 100px;
}

.footer-terms {
  display: flex;
}

@media (max-width: 768px) {
  .footer > div {
    max-width: 100%;
  }

  .footer > div > h2 {
    text-align: center;
  }

  .footer-sec {
    display: flex;
    margin-left: 10px;
  }
  
  .subs-input {
    width: 75%;
  }

  .footer-list > li {
    margin-bottom: 5px;
  }

  .btm-footer {
    padding-top: 0px;
    display: block;
    text-align: center;
  }

  .footer-terms {
    justify-content: center;
  }

  .auto-left {
    margin-left: 0;
  }
}
