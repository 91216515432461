
.button {
    border: none;
    background-color: #556b2f;
    border-radius: 40px;
    font-size: 16px;
    color: #ffffff;
    cursor: pointer;
}

.small {
    padding: 10px 20px;
}

.medium {
    padding: 10px 40px;
}

.large {
    padding: 10px 60px;
}