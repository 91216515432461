/* Section 1 CSS */

.discover-sec {
  display: flex;
  padding: 20px 0;
}

.desc-discover {
  max-width: 500px;
  color: #000000;
  margin: 40px 0;
}

.disc-left {
  padding-right: 40px;
  text-align: left;
}

@media only screen and (max-width: 768px) {
  .discover-section {
    max-width: 100%;
  }

  .desc-discover {
    margin: 40px 10px;
  }

  .discover-sec {
    display: block;
    padding: 20px 0 0;
  }

  .disc-left {
    padding: 0;
    text-align: center;
    padding-bottom: 20px;
  }
}

/* End of Section 1 CSS */

/* Section 2 CSS */

.begin-sec {
  background-color: #f5f5f5;
  padding: 46px 50px;
}

.symptom-tab {
  display: flex;
}

.symptom-tab > div {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  padding: 16px 20px;
  background-color: #ffffff;
  border-radius: 40px;
  margin: 10px;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.symptom-tab > div:hover {
  background-color: rgba(236, 147, 174, 0.62);
  color: #ffffff;
}

.symptom-card {
  display: flex;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 20px;
  text-align: center;
}

.img-left > img {
  width: 210px;
  height: 210px;
  object-fit: cover;
  border-radius: 100px;
}

.unsure {
  display: flex;
  background-color: #ffffff;
  padding: 20px 40px 10px 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

.chat-with-us {
  cursor: pointer;
  border: 1px solid #556b2f;
  box-shadow: 0.71px 0.71px black;
  border-radius: 30px;
  padding: 10px 80px;
}

.assessment-selected {
  background-color: #ec93ae !important;
  color: #ffffff;
} 

@media only screen and (max-width: 768px) {
  .symptom-tab {
    flex-wrap: wrap;
  }

  .symptom-tab > div {
    margin: 10px 5px;
  }

  .img-left {
    display: none;
  }

  .unsure {
    display: block;
  }

  .chat-with-us {
    padding: 10px;
    text-align: center;
  }
}

/* End of Section 2 CSS */

/* Section 3 CSS */

.unlock > h1 {
  margin-bottom: 30px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 elements in a row for desktop */
  gap: 20px;
}

.unlock > button {
  display: block;
  margin: 40px auto;
}

@media (max-width: 768px) {
  /* For mobile devices */
  .unlock {
    width: 100%;
  }

  .unlock > h1 {
    text-align: center;
  }

  .grid-container {
    width: 100%;
    justify-items: center;
    grid-template-columns: 1fr; /* 1 element in a row for mobile */
  }
}

.grid-item {
  background-color: #f2f2f2;
  padding: 20px;
  text-align: center;
}
/* End of Section 3 CSS */

/* Section 4 */

.processes-section {
  background-color: #f6c9d6;
}

.processes-section > div > h1 {
  padding: 51px 0 30px;
}

.process-cards {
  display: flex;
  padding: 0 0 20px;
}

.process-card > img {
  width: 100px;
  height: 100px;
  border-radius: 100px;
}

.process-card > p {
  margin: 20px 0;
}

@media (max-width: 768px) {
  .processes-section > div {
    width: 100%;
    margin: auto;
  }

  .processes-section > div > h1 {
    text-align: center;
    padding: 20px 30px;
  }

  .process-cards {
    display: block;
  }

  .process-card {
    text-align: center;
  }

  .process-card > p {
    font-weight: 300;
  }
}
/* End of Section 4 */

/* Section 5 */
.connect-section > div {
  display: flex;
}

.connect-left {
  margin-right: 200px;
}

.connect-left > h1 {
  margin-bottom: 50px;
}

.connect-left > p {
  margin-bottom: 70px;
}

.connect-right > img {
  width: 317px;
  border-radius: 100%;
}

@media (max-width: 768px) {
  .connect-section > div {
    width: 100%;
    text-align: center;
  }

  .connect-left {
    margin: 0;
  }

  .connect-left > p {
    margin: 0 10px 70px;
  }

  .connect-left > h1 {
    text-align: center;
    padding: 20px 30px;
    margin-bottom: 30px;
  }

  .connect-right > img {
    display: none;
  }
}
/* End of Section 5 */
