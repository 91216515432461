.login-page {
    max-width: 1082px;
    margin: 100px auto;
}

.send-otp-section, .verify-otp-section {
    display: none;
}

.active {
    display: block;
}

.form > input {
    display: block;
    margin: 30px auto;
}

.form > .mobile {
    padding: 5px 20px;
    border: none;
    width: 25%;
    border-bottom: 1px solid black;
}

.otp {
    padding: 5px 20px;
    border: none;
    width: 25%;
    border-bottom: 1px solid black;
}
  .otp-page {
    padding: 150px 0;
  }

  .otp-input {
    display: block;
  }

  @media (max-width: 768px) { 
    .form > .mobile {
        width: 250px;
    }
  }
 