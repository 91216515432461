.top-sec {
    font-weight: 700;
}

.top-sec > span {
    margin: 0 10px;
}

.nav-list > li {
    font-weight: 600;
    margin: 20px;
    cursor: pointer;
}

.assess-drop {
    padding: 10px;
}

.assess-drop > li {
    padding: 20px;
    background-color: #f0f0f2;
    cursor: pointer;
}

.border-el {
    border-bottom: 1px solid black;
    padding-bottom: 20px;
}