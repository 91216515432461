.outer-padding {
  padding: 40px 80px;
  background: #f6c9d6;
}

@media only screen and (max-width: 768px) {
  .outer-padding {
    padding: 40px 10px;
  }
}

.content-section {
  background-color: white;
  padding: 40px 20px;
}

.content-section > h2 {
  font-size: 27px;
}

.content-section > h3 {
  font-size: 27px;
}

.content-section > p {
  font-size: 16px;
}
