
.card-pot {
    width: 351px;
    height: 351px;
}

.card-pot > img {
    width: 84px;
    height: 84px;
    border-radius: 50px;
}