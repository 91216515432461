.thanks {
  padding-top: 20px;
  margin: 10px;
}

.order-details {
  border: 1px solid;
}

.coupon-valid {
  padding-bottom: 10px;
  border-bottom: 1px solid black;
}

.judgement-free {
  background-color: #f5f5f5;
  font-size: 14px;
}
