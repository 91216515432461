.card-carousel {
    display: flex; /* Make cards horizontal */
    justify-content: center;
    white-space: nowrap; /* Prevent wrapping on single line */
    margin: 40px 20px;
  }

  .card-carousel-block {
    display: block; /* Make cards horizontal */
    justify-content: center;
    white-space: nowrap; /* Prevent wrapping on single line */
    margin: 40px 20px;
  }

  @media (max-width: 768px) { 
    .card-carousel {
      justify-content: start;
      overflow-x: scroll; /* Enable horizontal scroll */
      overflow-scrolling: touch; /* Enable smooth scrolling on mobile */
      scroll-snap-type: x mandatory; /* Enable scroll snapping */
      scroll-snap-align: start; /* Snap cards to the left edge */
    }
  }
  
  .card {
    /* Existing styles */
    flex-shrink: 0; /* Prevent cards from shrinking */
    scroll-snap-align: start; /* Snap cards to the left edge */
    margin: 0 10px; /* Adjust spacing between cards */
    width: 170px;
    text-wrap: wrap;
    border: 1px solid gray;
    border-radius: 10px;
    padding: 10px 20px;
  }

  .margin-flex {
    margin: auto 10px !important;
  }

  .card-body {
    margin-top: 20px;
  }
  
  /* Optional: Media query for mobile responsiveness */
  @media (max-width: 768px) {
    .card-carousel {
      scroll-snap-type: none; /* Disable snapping on small screens */
    }
  }