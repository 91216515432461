.grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    gap: 1rem;
    padding: 0 20px;
  }

  .teams-content {
    max-width: 1082px;
    margin: auto;
  }

  .img-height {
    height: 250px;
  }

  .team-card {
    width: 355px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 5px;
  }
  
  .title-card {
    display: flex;
    margin: 0px 20px 15px;
  }

  .title-card > .left {
    width: 35%;
  }

  .title-card > .right {
    width: 65%;
    margin: auto;
  }

  .first-exp {
    background-color: #EC93AE;
    padding: 5px;
    border-radius: 5px;
    margin-right: 5px;
    font-size: 14px;
  }

  .therapy-type {
    background-color: #EC93AE;
    padding: 5px;
    border-radius: 5px;
    margin-right: 5px;
    font-size: 14px;
    width: fit-content;
    margin-top: 5px;
  }

  .top-choice {
    display: block;
    background: #556B2F;
    color: #ffffff;
    width: fit-content;
    padding: 5px 25px;
    border-radius: 0px 0px 10px 0px;
    text-transform: uppercase;
    font-size: 13px;
  }

  .second-exp {
    background-color: lightgray;
    padding: 5px;
    border-radius: 5px;
    margin-right: 5px;
    font-size: 14px;
  }

  .doctor-img {
    width: 100px;
    height: 100px;
  }
  
  .view-btn {
    background-color: white;
    color: #556b2f;
    border: 1px black solid;
    font-weight: 700;
    padding: 10px 10px !important;
    margin-right: 10px;
  }
  
  @media only screen and (max-width: 768px) {
    .grid {
      grid-template-columns: 1fr;
    }

    .team-card {
      margin: 0;
      margin-bottom: 10px;
      width: 100%;
    }

    .team-card > button {
      padding: 10px;
    }

    .teams-content {
      min-width: 400px;
      min-height: 800px;
      max-width: 100%;
      text-align: center;
      margin: 20px 0;
    }
  }