@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,500;1,400&display=swap");

body {
  margin: 0;
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  font-family: "Roboto", sans-serif;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

.min-dim {
  min-width: 1082px;
  min-height: 800px;
}

.header-font {
  font-size: 27px;
  font-weight: bold;
}

.section {
  max-width: 1082px;
  margin: 25px auto;
}

.page {
  min-width: 400px;
  min-height: 800px;
  max-width: 1082px;
  margin: auto;
  margin-bottom: 20px;
}

.small-header-font {
  font-size: 17px;
  font-weight: bold;
}

.text-font {
  font-size: 16px;
}

.small-text-font {
  font-size: 12px;
}

.loader-modal {
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
}

.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.icon-class {
  margin-left: 10px;
}

.color-healthship {
  color: #d9275c;
}

.black {
  color: #000000;
}

.flex {
  display: flex;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.ph-50 {
  padding: 0 50px;
}

.pointer {
  cursor: pointer;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-50 {
  margin-top: 50px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.mb-5 {
  margin-bottom: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-50 {
  margin-right: 50px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-5 {
  margin-left: 5px;
}

.ml-auto {
  margin-left: auto;
}

.my-10 {
  margin: 10px 0;
}

.my-20 {
  margin: 20px 0;
}

.mx-20 {
  margin: 0 20px;
}

.mx-50 {
  margin: 0 50px;
}

.mx-10 {
  margin: 0 10px;
}

.m-10 {
  margin: 10px;
}

.m-auto {
  margin: auto;
}

.px-10 {
  padding: 0 10px;
}

.py-10 {
  padding: 10px 0;
}

.p-10 {
  padding: 10px;
}

.p-20 {
  padding: 20px;
}

.px-20 {
  padding: 0 20px;
}

.py-20 {
  padding: 20px 0;
}

.pt-10 {
  padding-top: 10px;
}

.pt-20 {
  padding-top: 20px;
}

.pb-5 {
  padding-bottom: 5px;
}

.rad-10 {
  border-radius: 10px;
}

.rad-40 {
  border-radius: 40px;
}

.rad-50 {
  border-radius: 50px;
}

.f-10 {
  font-size: 10px;
}

.f-14 {
  font-size: 14px;
}

.white {
  color: #ffffff;
}

.bg-lp {
  background-color: #ec93ae;
}

.w-50 {
  width: 50%;
}

.w-100 {
  width: 100%;
}

.none {
  display: none;
}

.not-found {
  width: -webkit-fill-available;
  /* height: -webkit-fill-available; */
}

.not-found-mobile {
  display: none;
}

@media (max-width: 768px) {

  .min-dim {
    min-width: 400px;
    min-height: 800px;
  }

  .loader {
    left: 40%;
  }

  .not-found {
    display: none;
  }

  .not-found-mobile {
    display: block;
    width: -webkit-fill-available;
    /* height: -webkit-fill-available;   */
  }
}

.react-multi-carousel-list {
  padding-bottom: 20px; /* Adjust the padding value as needed */
}
